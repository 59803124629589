import React from "react";
import SaudiRiyalSVG from "./SaudiRiyalSVG";

const LogoContainer = ({ amount, description }) => {
  return (
    <div
      className="p-4"
      style={{ boxShadow: "1px 1px 5px lightgray", height: "100%" }}
    >
      <h1
        className="mb-4"
        style={{
          fontWeight: "700",
          color: "#31538f",
          fontFamily: "LogoFont",
          fontSize: "48px",
        }}
      >
        <span style={{ color: "#2c3850" }}>CPV</span> ARABIA
      </h1>

      <h3>
        {amount} <SaudiRiyalSVG color="231f20" />
      </h3>

      <p>{description}</p>

      <div className="d-flex justify-content-center align-items-center mt-5">
        <svg
          width="256px"
          height="256px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M3 14V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V14H3ZM3 14V8C3 6.89543 3.89543 6 5 6H12M17 6H16V10H22V6H21M17 6V4C17 2.89543 17.8954 2 19 2L-nan -nanL-nan -nanL19 2C20.1046 2 21 2.89543 21 4V6M17 6H21"
              stroke="#2068B9"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default LogoContainer;
